@import '../../../styles/colors';


.editLink {
  margin-right: 10px; 
  display: inline-block; 
  vertical-align: middle; 
  color: black; 
  text-decoration: none; 
  
  
  &:hover {
    text-decoration: underline; 
  }
}

.deleteIcon {
  display: inline-block; 
  vertical-align: middle; 
  &:hover {
    text-decoration: underline; 
  }
  cursor: pointer;
}


.formContainer {
  width: 50%;
  padding: 2rem;

}

.emotionImage {
  max-width: 50%; 
  height: auto; 
}


@media screen and (max-width: 768px) {
  .formContainer {
      box-sizing: border-box;
      width: 100%;
      padding: 1rem;
  }
}