@import '../../../styles/colors';

.filtersContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 3rem;

    .filterContainer {
        display: flex;
        flex-direction: row;
        align-items: center;

        :global(.selectField) {
            min-width: 20rem;
            max-width: 30%;
            margin-right: 4rem;
            margin-left: 1rem;
        }
    }
}

.treeContainer {
    > div > div {
        div {
            margin-left: 4rem;
        }
    }

    div {

        &.subcontainer {
            margin: 1rem 0;
        }

        span.type {
            display: inline-block;
            background-color: $color-gray;
            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;
            border-bottom: 1px solid $color-gray;
            padding: 0.3rem 0.8rem;
            margin: 0;

            &.green {
                background-color: $color-green;
                border-bottom: 1px solid $color-green;
            }

            &.orange {
                background-color: $color-orange;
                border-bottom: 1px solid $color-orange;
            }
        }

        span.name {
            display: inline-block;
            border-bottom: 1px solid $color-gray;
            padding: 0.3rem 1rem;
            margin: 0;

            &.green {
                border-bottom: 1px solid $color-green;
            }

            &.orange {
                border-bottom: 1px solid $color-orange;
            }
        }

        .field {
            display: flex;
            flex-direction: row;

            > div {
                border-bottom: 1px solid $color-gray;
                min-width: 7rem;
                margin-top: 0.5rem;
            }

            >div:first-child {
                font-weight: bold;
            }

            >div:last-child {
                min-width: 20rem;
            }

            div {
                margin-left: 0.5rem;

                &.correct {
                    color: $color-green;
                    font-weight: bold;
                }
            }
        }
    }
}