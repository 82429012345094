@import '../../../styles/colors';

.container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 60vw;
    max-height: 60vh;
    overflow-y: auto;

    .item {
        height: 150px;
        min-width: 150px;
        text-align: center;
        border: 3px solid $color-gray-dark;
        border-radius: 0.5rem;
        background-color: $color-gray;
        margin-right: 1rem;
        position: relative;
        box-sizing: border-box;
    
        img {
            height: 100%;
        }

        .filename {
            position: absolute;
            bottom: 3px;
            background-color: $color-bg;
            line-height: 0.6rem;
            font-size: 0.8rem;
            max-width: 100%;
            overflow-wrap: break-word;
            text-align: left;
            padding: 5px;
            box-sizing: border-box;
            opacity: 0.9;
        }

        &.selected {
            border: 3px solid $color-green;
            background-color: $color-green-50;

            img {
                opacity: 0.7;
            }
        }
    }
}
