@import '../../../../styles/colors';


.buttonsContainer {
    margin-top: 1rem;
}

.list {
    .titleCell {
        white-space: nowrap;
    }
}
