@import '../../styles/colors';


.oldComments {
    background-color: $color-orange-light;
    min-height: 2rem;
    padding: 0.5rem 0 0 1rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}

.commentsList {
    max-width: 700px;

    &-test {
        color: #fff;
    }

    .gray {
        color: $color-gray-dark;
    }

    .replyForm {
        display: block;
    }

    .commentItemContainer {
        padding: 0 1rem;
        margin-bottom: 1rem;
        border-radius: 5px;

        &.active {
            background-color: $color-gray-light;
        }

        .commentInfo {
            .row {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
            }

            .unresolvedIcon {
                color: $color-error;
                margin-right: 0.5rem;
                margin-top: 0.3rem;
            }

            .author {
                margin-right: 1rem;
            }

            .dates {
                color: $color-gray-dark;
                margin-right: 1rem;
                font-size: 0.7rem;
                line-height: 1rem;
            }

            .taskInfo {
                font-weight: bold;
                color: $color-orange-dark;
            }
        }

        .commentContent {
            display: inline-block;
            background-color: $color-gray;
            padding: 1px 0.5rem;
            border-radius: 5px;

            p {
                margin: 0.5rem 0;
            }
        }

        .subComments {
            margin-left: 4rem;
            margin-bottom: 1rem;

            .row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 0.5rem;


                .line {
                    height: 1px;
                    min-width: 10rem;
                    flex-grow: 1000;
                    border-bottom: 1px solid $color-gray;
                }
            }

            .subCommentsContainer {
                margin-top: 1rem;
            }
        }

        .commentItemButtons {
            margin: 0rem 0 0 4rem;

            button {
                margin-bottom: 1rem;
            }
        }

        .replyInThreadFormContainer {
            margin-left: 4rem;
        }
        
    }
}