@import '../../../../styles/colors';


.tableTitle {
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid $color-orange;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.disabledText {
    color: $color-gray;
    font-size: 1.5rem;
    text-align: center;
    margin: 2rem;
}

.formContainer {
    box-sizing: border-box;
    max-width: 40%;
    margin: 1rem 2rem;
}

.commentsButton {
    margin: 0 0 2rem 2rem;
}


@media screen and (max-width: 768px) {

    .formContainer {
        width: 100%;
        padding: 1rem;
    }
}