@import '../../../styles/colors';

.list {
    width: 100%;
    border: 1px solid $color-gray;

    td:first-child {
        position: relative;
        width: 10rem;

        .markContainer {
            height: 100%;
            display: flex;
            flex-direction: row;

            .mark {
                position: absolute;
                top: 10%;
                left: 0;
                height: 80%;
                width: 1px;
                float: left;
                border-left: 5px solid $color-green;
                margin-right: 2rem;
        
                &.disabled {
                
                    border-color: $color-orange;
                }
            }

            .title {
                margin-left: 1rem;
            }
        }
    }

    .icon {
        max-width: 200px;
        max-height: 100px;
    }

    .enableBtn {
        color: $color-orange-dark;
    }
}

.addParticipantPopup {

    select {
        width: 100%;
        font-size: 1.7rem;
        margin: 0.5rem 0;
    }

}

.buttons {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}