@import '../../../../styles/colors';

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.list {
    width: 100%;
    border: 1px solid $color-gray;

    &.simple {
        max-width: 740px;
        
        td {
            cursor: pointer;
        }

        .selected {
            td {
                background-color: $color-green-light;
            }
        }

        td:last-child {
            min-width: 90%;
        }
    }

    td:first-child {
        position: relative;

        .titleContainer {
            height: 100%;
            display: flex;
            flex-direction: row;

            .mark {
                position: absolute;
                top: 10%;
                left: 0;
                height: 80%;
                width: 1px;
                float: left;
                border-left: 5px solid $color-green;
                margin-right: 2rem;

                &.disabled {
                
                    border-color: $color-orange;
                }
            }

            .title {
                margin-left: 1rem;
            }
        }
    }

    td {
        .userInfo {
            display: flex;
            flex-direction: row;
            color: $color-gray-text;

            .email {
                min-width: 20rem;
            }

            .userRole {
                color: $color-orange-dark;
                margin-left: 4rem,
            }
        }
    }
}


.pager {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}