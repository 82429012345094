@import '../../../styles/colors';

.tabs {
    .tabsContainer {
        width: 100%;
        overflow-x: auto;

        > ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            border-bottom: 3px solid $color-green;
            margin: 0;
            padding: 0 0 0 0.7rem;

            > li {
                white-space: nowrap;
                color: $color-gray-text;
                background-color: $color-gray;
                margin: 0;
                position: relative;
                cursor: pointer;
                /* transition: all 250ms ease; */
                text-align: center;
                box-sizing: border-box;
                margin-right: 0.8rem;
                border-radius: 0.8rem 0.8rem 0 0;
                padding: 0.14rem 2rem;
                box-shadow: #00000011 0 -1px 2px;

                &:before {
                    background-color: $color-gray;
                    display: block;
                    content: " ";
                    position: absolute;
                    height: 100%;
                    width: 1rem;
                    top: 0;
                    left: -0.31rem;
                    transform: skew(-20deg, 0deg);
                    border-radius: 5px 0 0 0;
                    box-shadow: #00000011 -1px -1px 2px, inset #ffffff99 1px 0;
                }

                &:after {
                    background-color: $color-gray;
                    display: block;
                    content: " ";
                    position: absolute;
                    height: 100%;
                    width: 1rem;
                    top: 0;
                    right: -0.32rem;
                    transform: skew(20deg, 0deg);
                    border-radius: 0 5px 0 0;
                    box-shadow: #00000011 1px -1px 2px;
                }

                &.active {
                    z-index: 5;
                    background-color: $color-green;
                    color: $color-txt;

                    &:before, &:after {
                        background-color: $color-green;
                    }
                }
            }
        }
    }

    .contentContainer {
        display: none;

        &.active {
            display: block;
        }
    }
}