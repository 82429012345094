@import '../../styles/colors';

.search {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    .searchIcon {
        position: absolute;
        margin: 0.3rem 0 0 0.3rem;
        color: $color-orange;
    }

    .clearSearchFieldBtn {
        color: $color-gray-dark;
        position: absolute;
        margin-top: 0.3rem;
        margin-left: -1.5rem;
    }

    input[type=text].searchField {
        width: 8rem;
        padding: 0 2rem;
        margin-right: 0.5rem;
    }

    
    &.active {
        input[type=text].searchField {
            width: 33rem;
        }
    }
}