@import '../../styles/colors';

.list {

    td {
        &:first-child {
            position: relative;

            .markContainer {
                height: 100%;
                display: flex;
                flex-direction: row;
            
                .mark {
                    position: absolute;
                    top: 10%;
                    left: 0;
                    height: 80%;
                    width: 1px;
                    float: left;
                    border-left: 5px solid $color-green;
                    margin-right: 2rem;
            
                    &.disabled {
                    
                        border-color: $color-orange;
                    }
                }
            
                .title {
                    margin-left: 1rem;
                    flex-grow: 20;
                }
            }

        }

        .scene {
            color: $color-gray-text;

            span {
                font-weight: bold;
            }
        }

        .episode {
            color: $color-gray-dark;

            span {
                font-weight: bold;
            }
        }

        .narrative {
            color: $color-blue;

            span {
                font-weight: bold;
            }
        }
    }
}