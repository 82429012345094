@import '../../../styles/colors';

.searchField {
    margin: 1rem 0;
    position: relative;

    svg {
        position: absolute;
        margin: 0.3rem;
        color: $color-gray-dark;
    }

    .searchIcon {
        left: 0;
        color: $color-orange;
    }

    .clearField {
        margin-left: -1.2rem;
        cursor: pointer;
    }

    input[type=text].searchInputField {
        padding-left: 1.5rem;
        padding-right: 2rem;

        &::placeholder {
            color: $color-gray;
        }
    }
}