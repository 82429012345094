@import "../../../styles/colors";

.list {
    width: 100%;
    border: 1px solid $color-gray;

    td:first-child {
        position: relative;
        width: 5rem;

        .markContainer {
            height: 100%;
            display: flex;
            flex-direction: row;

            .mark {
              position: absolute;
              top: 10%;
              left: 0;
              height: 80%;
              width: 1px;
              float: left;
              border-left: 5px solid $color-green;
              margin-right: 2rem;

              &.disabled {

                  border-color: $color-orange;
              }
            }

            .title {
                margin-left: 1rem;
            }

          }
    }

    .enableBtn {
        color: $color-orange-dark;
    }
}


.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.pager {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
