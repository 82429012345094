@import '../../../styles/colors';

.container {

    .inputContainer {
        display: flex;
        flex-direction: 'row';

        a {
            margin-left: 1rem;
        }
    }

    .values {
        .valueItem {
            margin-top: 0.5rem;
            display: flex;
            flex-direction: row;

            .value {
                border-bottom: 1px solid $color-gray;
                width: calc(100% - 7rem);
            }

            .actions {
                width: 7rem;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}