@import '../../styles/colors';

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: $color-gray-dark;
}