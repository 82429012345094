@import '../../../styles/colors';

.editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .formContainer {
        width: 40%;
        padding: 2rem;
    }

    .videoContainer {
        width: 50%;
        height: 500px;
        padding: 2rem;
    }
}



.list {
    &.list {
        td {
            &:first-child {
                position: relative;
                width: 150px;
                a {
                    float: right;
                    margin: 0 5px;
                }

                .markContainer {
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                
                    .mark {
                        position: absolute;
                        top: 10%;
                        left: 0;
                        height: 80%;
                        width: 1px;
                        float: left;
                        border-left: 5px solid $color-green;
                        margin-right: 2rem;
                
                        &.disabled {
                        
                            border-color: $color-orange;
                        }
                    }
                
                    .title {
                        margin-left: 1rem;
                        flex-grow: 20;
                    }
                }
            }
        }
    }
}

.contentPreviewContainer {
    width: 480;
    height: 15rem;
    overflow: hidden;
    position: relative;

    .contentPreview {
        width: 768;
        transform: scale(0.5, 0.5) translate(-50%, -50%);
        position: absolute;
    }
}

@media screen and (max-width: 768px) {
    .editor {

        .formContainer {
            box-sizing: border-box;
            width: 100%;
            padding: 1rem;
        }
    }
}