@import '../../../styles/colors';

.list {
    th:first-child,
    td:first-child {
        width: 3rem;
        text-align: center;
    }


    th:last-child,
    td:last-child {
        width: auto !important;
    }

}