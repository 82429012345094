$color-txt: #000000;

$color-orange-light: #FEE6CD;
$color-orange-lightest: lighten($color-orange-light, 6%);
$color-orange: darken($color-orange-light, 20%);
$color-orange-dark: darken($color-orange-light, 45%);

$color-txt-comment: #737373;
$color-error: #F83232;
$color-red: darken($color-error, 15%);

$color-white: #FFFFFF;
$color-black: #222222;

$color-bg: #FAFAFA;
$color-bg-header: $color-white;


$color-blue: #27adff;

$color-border: #989898;
$color-gray: #D9D9D9;
$color-gray-light: lighten($color-gray, 10%);
$color-gray-dark: darken($color-gray, 20%);
$color-gray-text: darken($color-gray, 40%);

$color-green: #68CACB;
$color-green-light: lighten($color-green, 30%);
$color-green-50: rgba($color-green, 0.5);

$color-red-light: #ff6666;

$color-btn-red: darken($color-error, 15%);
$color-btn-green: $color-green;

