@import '../../../styles/colors';

.checkboxContainer {
    display: flex;
    flex-direction: row;


    .checkbox {
        margin-top: 0.2rem;
        margin-right: 1rem;
        width: 1.5rem;
        height: 1.4rem;
        border: 2px solid $color-border;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        svg {
            font-size: 1rem;
            color: $color-gray-dark;
        }
    }
    
    .label {
        cursor: pointer;
        align-self: center;

        &.disabledLabel {
            cursor: auto;
        }
    }

    &.checked {
        .checkbox {
            background-color: $color-green;
            border-color: $color-green;


            svg {
                color: $color-txt;
            }
        }
    }

    &.disabled {
        .checkbox {
            opacity: 0.5;
            border-color: $color-border;

            svg {
                color: $color-bg-header;
            }
        }

        &.checked {
            .checkbox {
                background-color: $color-border;
            }
        }
    }
}