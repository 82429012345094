@import '../../../../styles/colors';

.editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .formContainer {
        width: 40%;
        padding: 2rem;
    }

    .videoContainer {
        width: 50%;
        height: 500px;
        padding: 2rem;

        .controls {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            .start, .end {
                display: flex;
                flex-direction: column;

                .value {
                    height: 1rem;
                    margin: 1.7rem;
                    text-align: center;
                    color: $color-gray-text;
                    font-size: 2rem;
                    font-weight: bold;
                }

                button {
                    width: 12rem;
                }
            }
        }
    }
}

.list {

    td {
        &:first-child {
            position: relative;

            .markContainer {
                height: 100%;
                display: flex;
                flex-direction: row;
            
                .mark {
                    position: absolute;
                    top: 10%;
                    left: 0;
                    height: 80%;
                    width: 1px;
                    float: left;
                    border-left: 5px solid $color-green;
                    margin-right: 2rem;
            
                    &.disabled {
                    
                        border-color: $color-orange;
                    }
                }
            
                .title {
                    margin-left: 1rem;
                    flex-grow: 20;
                }
            }

        }
    }
}



@media screen and (max-width: 768px) {
    .editor {

        .formContainer {
            box-sizing: border-box;
            width: 100%;
            padding: 1rem;
        }
    }
}