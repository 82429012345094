@import '../../../styles/colors';

    :global(.markerArrowGreen) {
        color: $color-green;
    }

    :global(.markerArrowBlue) {
        color: $color-blue;
    }

    :global(.markerArrowRed) {
        color: $color-red-light;
    }

    :global(.markerArrowGray) {
        color: $color-gray-dark;
    }

    :global(.connection) {
        path {
            stroke: currentColor;
            fill: none;
            stroke-width: 4;
            color: $color-gray-dark;
        }

        &:global(.endArrow) {
            path {
                marker-end: url(#marker-arrow-gray-end);
            }
        }

        &:global(.startArrow) {
            path {
                marker-start: url(#marker-arrow-gray-start);
            }
        }

        &:global(.thin) {
            path {
                stroke-width: 2;
            }
        }

        &:global(.bold) {
            path {
                stroke-width: 6;
            }
        }

        &:global(.dotted) {
            path {
                stroke-dasharray: 4 4;
            }
        }

        &:global(.dashed) {
            path {
                stroke-dasharray: 20 4;
            }
        }

        &:global(.green) {
            path {
                color: $color-green;
            }

            &:global(.endArrow) {
                path {
                    marker-end: url(#marker-arrow-green-end);
                }
            }

            &:global(.startArrow) {
                path {
                    marker-start: url(#marker-arrow-green-start);
                }
            }
        }

        &:global(.blue) {
            path {
                color: $color-blue;
            }

            &:global(.endArrow) {
                path {
                    marker-end: url(#marker-arrow-blue-end);
                }
            }

            &:global(.startArrow) {
                path {
                    marker-start: url(#marker-arrow-blue-start);
                }
            }
        }

        &:global(.red) {
            path {
                color: $color-red-light;
            }

            &:global(.endArrow) {
                path {
                    marker-end: url(#marker-arrow-red-end);
                }
            }

            &:global(.startArrow) {
                path {
                    marker-start: url(#marker-arrow-red-start);
                }
            }
        }
    }


    :global(.element) {

        rect {
            fill: none;
        }

        image {
            width: 100px;
            height: 50px;
            fill: none;
            background-color: transparent;

            &.onlyImage {
                height: 100px;
            }
        }

        text {
            fill: $color-black;
        }

        &:global(.blue) {
            text {
                fill: $color-white;
            }

            rect {
                fill: $color-blue;
            }
        }

        &:global(.green) {
            rect {
                fill: $color-green;
            }
        }

        &:global(.gray) {
            rect {
                fill: $color-gray;
            }
        }

        &:global(.red) {
            text {
                fill: $color-white;
            }

            rect {
                fill: $color-error;
            }
        }

        &:global(.bordered) {

            rect {
                stroke-width: 3;
                fill: none;
                stroke: $color-gray-dark;
            }

            &:global(.dotted) {
                rect {
                    stroke-dasharray: 2 2;
                }
            }

            &:global(.dashed) {
                rect {
                    stroke-dasharray: 15 4;
                }
            }

            &:global(.green) {
                rect {
                    fill: none;
                    stroke: $color-green;
                }
            }

            &:global(.blue) {
                rect {
                    fill: none;
                    stroke: $color-blue;
                }

                text {
                    fill: $color-txt;
                }
            }

            &:global(.red) {
                rect {
                    fill: none;
                    stroke: $color-error;
                }

                text {
                    fill: $color-txt;
                }
            }
        }
    }