@import '../../../styles/colors';

.uploader {
    .dropZone {
        border: 1px dashed $color-gray;
        width: 100%;
        min-height: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        input[type=file] {
            display: none;
        }

        .inactiveText {
            text-align: center;
            color: $color-gray-dark;
            font-size: 0.8rem;
            line-height: 1em;
        }
    }

    .filesList {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .filePreview {
            position: relative;
            display: flex;
            flex-direction: row;
            padding: 2px 1.5rem 2px 5px;
            margin-right: 2rem;
            border-bottom: 2px solid transparent;

            &:hover {
                border-bottom: 2px solid $color-orange;
            }

            a {
                text-decoration: none;
                color: $color-txt;

                svg {
                    margin-right: 0.3rem;
                }
            }

            .deleteBtn {
                display: none;
                margin-left: 0.5rem;
                cursor: pointer;
                color: $color-gray;
                position: absolute;
                right: 5px;

                &:hover {
                    color: $color-btn-red;
                }
            }

            &:hover {
                .deleteBtn {
                    display: block;
                }
            }
        }
    }

    .imagePreview {
        margin: 1rem 1rem 1rem 0;
        height: 10rem;
        width: 10rem;
        text-align: center;
        border: 1px solid $color-gray;
        padding: 0.5rem;
        border-radius: 0.3rem;

        .imageContainer {
            height: 10rem;
            width: 10rem;
            
            img {
                max-height: 100%;
                max-width: 100%;
            }
        }

        .fileName {
            position: relative;
            margin-top: -2rem;
            display: flex;
            justify-content: space-between;
            color: $color-txt;

            span {
                word-wrap: break-word;
                text-align: left;
                background-color: rgba($color-bg, 0.7);
                padding: 0 0.2rem;
                display: block;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: calc(100% - 2rem);
            }
        }

        .deleteBtn {
            display: none;
            margin-left: 0.5rem;
            cursor: pointer;
            background-color: rgba($color-bg, 0.7);
            padding: 0 0.2rem;

            &:hover {
                color: $color-btn-red;
            }
        }


        &:hover {
            .deleteBtn {
                display: block;
            }
        }
    }
}