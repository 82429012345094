@import './colors';

        
input[type=text]:not(.react-datetime-picker__inputGroup__input),
input[type=number]:not(.react-datetime-picker__inputGroup__input),
input[type=email],
input[type=password],
textarea,
select:not(.react-datetime-picker__inputGroup__input),
.selectField {
    line-height: 1.5rem;
    font-size: 1.2rem;
    border-radius: 4px;
    border: 1px solid $color-border;
    width: 100%;
    padding: 0 0.5rem;
    box-sizing: border-box;
    outline-color: $color-orange;

    &[disabled] {
        opacity: 0.4;
        color: $color-txt;
    }

}


.react-calendar {
    min-height: 19rem;
}
.react-datetime-picker__wrapper {
    border-radius: 4px;
    border: 1px solid $color-border !important;
}
.react-datetime-picker__inputGroup__input, .react-datetime-picker__inputGroup__leadingZero {
    font-size: 1.2rem;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: $color-gray;
}


.form-item {
    margin-bottom: 1rem;
    width: 100%;

    .form-label {
        font-size: 0.8rem;

        &.required:after {
            content: '*';
            color: $color-error;
        }
    }

    .errors {
        min-height: 1.5rem;
        display: block;
        font-size: 0.8rem;
        line-height: 1rem;
    }
}

.form-buttons {
    margin-bottom: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;


}

