@import '../../../styles/colors';

label.checkboxWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .checkboxContainer {

        input[type=checkbox] {
            display: none;
        }

        .checkbox {
            margin-top: 0.2rem;
            margin-right: 1rem;
            width: 1.5rem;
            height: 1.4rem;
            display: inline-block;
            border: 2px solid $color-border;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;

            svg {
                display: none;
                font-size: 1rem;
            }
        }

        input:checked ~ .checkbox {
            background-color: $color-green;
            border-color: $color-green;

            svg {
                display: block;
            }
        }

        input[disabled] ~ .checkbox {
            opacity: 0.5;
            border-color: $color-border;

            svg {
                color: $color-bg-header;
            }
        }

        input[disabled]:checked ~ .checkbox {
            background-color: $color-border;
        }
    }

    .labelText {
    }
}