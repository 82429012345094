@import "../../../styles/colors";


.editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .formContainer {
        width: 40%;
        padding: 2rem;
    }
}



@media screen and (max-width: 768px) {
    .editor {

        .formContainer {
            box-sizing: border-box;
            width: 100%;
            padding: 1rem;
        }
    }
}