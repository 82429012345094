@import '../../../styles/colors';

.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: $color-txt-comment;


    .user {
        margin-left: 0.5rem;
        height: 2rem;
        width: 2rem;
        font-size: 1rem;
        color: $color-txt-comment;
        border: 1px solid $color-txt-comment;
        border-radius: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: transparent;
    }
}