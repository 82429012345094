@import '../../styles/colors';

.main {
    background-color: $color-bg;
    min-height: 100vh;
    width: 100vw;
}

.header {
    box-sizing: border-box;
    width: 100vw;
    height: 4rem;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background-color: $color-bg-header;
    position: absolute;
    border-bottom: 2px solid $color-orange;

    .logoContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        .logo {
            width: 3rem;
        }
    }

    .menuContainer {
        display: flex;
        flex-direction: row;

        .search {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        .menuBtn {
            font-size: 1.5rem;
            cursor: pointer;
            margin: 0.3rem 1rem;
        }


        ul {
            display: flex;
            flex-direction: row;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0;
                padding: 0 0.5rem;
                border-left: 1px solid $color-gray-dark;

                &:first-child {
                    border: none;
                }

                a {
                    font-weight: 300;
                    text-decoration: underline;
                    padding: 0.3rem 1rem;

                    &.active {
                        font-weight: bold;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

:global {
    .mainsubmenu {
        background-color: $color-orange-light;
        border-bottom: 1px solid $color-orange;
        margin: 0 -1rem;
        padding: 0.2rem 0;

        > ul {
            list-style: none;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            padding: 0;
            margin: 0;

            li {
                border-right: 1px solid $color-gray;

                a {
                    font-weight: 300;
                    padding: 0.5rem 1rem;

                    &.active {
                        background-color: $color-orange;
                        text-decoration: none;
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }

                &:first-child {
                    border-left: none;
                }
            }
        }
    }
}


.auntentificated {
    .header {
        justify-content: space-between;
    }
}

.body {
    padding: 1rem;
    padding-top: 4rem;
    box-sizing: border-box;
    min-height: 100vh;

    :global(.breadcrumbs) {
        margin-bottom: 2rem;
        font-size: 0.9rem;
        font-weight: bold;

        :global(.breadcrumbsLink) {
            font-weight: normal;
            text-decoration: underline;
        }
    }
}

@media screen and (max-width: 768px) {
    .header {
        justify-content: center;
    }
}