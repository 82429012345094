@import '../../../../styles/colors';

.formContainer {
    width: 700px;
}



@media screen and (max-width: 768px) {
    .formContainer {
        width: 100%;
    }
}