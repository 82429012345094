@import '../../../styles/colors';

.list {
    width: 100%;
    border: 1px solid $color-gray;

    td:first-child {
        width: 5rem;
    }
}


.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.pager {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}