@import '../../../../styles/colors';

.filters {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;


    .filter {
        margin-right: 1rem;
        span {
            padding-left: 0.2rem;
        }

        &.filterService {
            width: 20rem;
        }
    }
  }

.buttonView{
    display: flex;
    flex-direction: row;
}
.list {
    width: 100%;
    border: 1px solid $color-gray;

    td:first-child {
        position: relative;

        .titleContainer {
            height: 100%;
            display: flex;
            flex-direction: row;

            .mark {
                position: absolute;
                top: 10%;
                left: 0;
                height: 80%;
                width: 1px;
                float: left;
                border-left: 5px solid $color-green;
                margin-right: 2rem;

                &.disabled {

                    border-color: $color-orange;
                }
            }

            .title {
                margin-left: 1rem;
            }
        }
    }

    th:nth-child(3), td:nth-child(3) {
        width: 7rem;
        text-align: center;
    }

    th:nth-child(4), td:nth-child(4) {
        width: 7rem;
        text-align: center;
    }

    .enableBtn {
        color: $color-orange-dark;
    }
}


.buttons {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
