@import "../../../styles/colors";


.editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .formContainer {
        width: 40%;
        padding: 2rem;
    }
}



@media screen and (max-width: 768px) {
    .editor {

        .formContainer {
            box-sizing: border-box;
            width: 100%;
            padding: 1rem;
        }
    }
}

.list {

    td {
        &:first-child {
            position: relative;

            &.character {
                display: flex;
                flex-direction: row;
                align-items: center;
                width: auto;
            }

            img.characterPhoto {
                display: block;
                width: 4rem;
                height: 4rem;
                margin-right: 2rem;
                box-sizing: border-box;
                clip-path: circle(50% at 50% 50%);
                border: 3px solid $color-btn-green;
                border-radius: 50%;
            }

            .markContainer {
                height: 100%;
                display: flex;
                flex-direction: row;

                .mark {
                    position: absolute;
                    top: 10%;
                    left: 0;
                    height: 80%;
                    width: 1px;
                    float: left;
                    border-left: 5px solid $color-green;
                    margin-right: 2rem;

                    &.disabled {

                        border-color: $color-orange;
                    }
                }

                .title {
                    margin-left: 1rem;
                    flex-grow: 20;
                }
            }

        }
    }

}
