@import '../../../styles/colors';

.button {
    min-height: 2.5rem;
    border-radius: 1.5rem;
    background-color: $color-btn-green;
    min-width: 13rem;
    font-size: 0.8rem;
    font-weight: bold;
    border: none;
    cursor: pointer;
    outline: none;
    text-align: center;
    position: relative;
    white-space: nowrap;
    padding: 0 3rem;

    span {
        color: $color-txt;
        white-space: nowrap;
        line-height: 1em;
    }

    &:global(.small) {
        min-width: 5rem;
    }

    &:global(.marginbottom) {
        margin-bottom: 5px;
    }

    &:global(.mini) {
        min-width: 2rem;
        padding: 0 1rem;
        min-height: 1.5rem;
    }

    &:global(.orange) {
        background-color: $color-orange-light;
    }

    &:global(.red) {
        background-color: $color-error;
        color: $color-bg;

        span {
            color: $color-bg;
        }
    }

    &:global(.gray) {
        background-color: $color-gray;
    }

    &:global(.outline) {
        background-color: transparent;
        color: $color-txt;
        border: 1px solid $color-txt;

        &:global(.gray) {
            background-color: $color-bg-header;
            border-color: $color-gray;
            color: $color-gray-text;

    span {
                color: $color-gray-text;
            }
        }
    }

    &:global(.text) {
        background-color: transparent;
        color: $color-txt;
        text-decoration: underline;
    }

    svg {
        position: absolute;
        font-size: 1.2rem;
        margin-top: -0.1rem;

        &:first-child {
            left: 1rem;
        }

        &:last-child {
            right: 1rem;
        }
    }

    &[disabled] {
        filter: grayscale(70%);
        opacity: 0.6;
        cursor: not-allowed;
    }

    
}


@media screen and (max-width: 768px) {
    .button {
        min-width: 7rem;
    }
}