@import '../../../../styles/colors';

.editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .formContainer {
        width: 40%;
        padding: 2rem;
    }


    .videoContainer {
        width: 50%;
        height: 440px;
        padding: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            margin-top: 2rem;
        }
    }
}


.list {

    td {

        img.characterPhoto {
            max-width: 4rem;
            max-height: 4rem;
            vertical-align: middle;
            margin-right: 2rem;
            box-sizing: border-box;
            clip-path: circle(50% at 50% 50%);
            border: 3px solid $color-btn-green;
            border-radius: 50%;
        }
    }
}

.addParticipantPopup {

    select {
        width: 100%;
        font-size: 1.7rem;
        margin: 0.5rem 0;
    }

}

.statusAddendum {
  align-items: left;
  color: #68CACB;
}

@media screen and (max-width: 768px) {
    .editor {

        .formContainer {
            box-sizing: border-box;
            width: 100%;
            padding: 1rem;
        }
    }
}

.exerciseEditor {
    width: 35rem;

    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 1rem;
    }

    .error {
        margin: 2rem 0;
        color: $color-error;
    }

    .questions {

        .nothing {
            color: $color-gray-dark;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
        }

        .question {
            .text {
                margin-top: 1rem;
            }

            .answers {
                .answersHeader {
                    margin-top: 1rem;
                    border-bottom: 1px solid $color-border;
                }

                .answer {
                    padding: 1rem;

                    .correct {
                        background-color: $color-green;
                        color: $color-white;
                        padding: 0 0.5rem;
                        margin-left: 2rem;
                    }

                    .answerFeedback {
                        color: $color-gray-dark;
                    }

                    &:nth-child(2n) {
                        background-color: $color-gray-light;
                    }
                }

            }
        }
    }
}
