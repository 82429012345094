@import '../../../styles/colors';

.editor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 1rem;

    .formContainer {
        width: 40%;
        padding: 2rem;
    }

}


:global(table.list) {
    &.list {
        td {
            &:first-child {
                position: relative;
                width: 150px;
                a {
                    float: right;
                    margin: 0 5px;
                }

                .markContainer {
                    height: 100%;
                    display: flex;
                    flex-direction: row;

                    .mark {
                        position: absolute;
                        top: 10%;
                        left: 0;
                        height: 80%;
                        width: 1px;
                        float: left;
                        border-left: 5px solid $color-green;
                        margin-right: 2rem;

                        &.disabled {

                            border-color: $color-orange;
                        }
                    }

                    .title {
                        margin-left: 1rem;
                        flex-grow: 20;
                    }
                }
            }
        }
    }
}
