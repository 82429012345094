@import '../../../styles/colors';

button {
    border: 1px solid $color-gray-dark;
    min-width: 2rem;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    margin-right: 5px;
    font-size: 0.8rem;

    &[disabled] {
        opacity: 0.5;
    }
}

span.less, span.greater {
    margin-right: 5px;
}

.selected {
    background-color: $color-green;
}