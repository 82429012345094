@import '../../../styles/colors';

.editLink {
  margin-right: 10px; 
  display: inline-block; 
  vertical-align: middle; 
  color: black; 
  text-decoration: none; 
  
  
  &:hover {
    text-decoration: underline; 
  }
}
