@import '../../../styles/colors';

.filters {
    display: flex;
    flex-direction: row;
    margin: 1rem 0;


    .filter {

        span {
            padding-left: 0.2rem;
        }

        &.filterNarrative {
            width: 20rem;
        }

        &.filterRole {
            margin: 0 1rem;
            min-width: 10rem;
            max-width: 10rem;
        }

        &.filterUser {
            margin: 0 1rem;
            min-width: 40rem;
            max-width: 40rem;
        }

    }
}

.board {
    display: flex;
    flex-direction: row;

    .boardColumn {
        width: 20rem;
        background-color: $color-gray;
        padding: 0.5rem;
        margin: 0 0.5rem 0 0;
        border-radius: 5px;

        .borderColumnHeader {
            min-height: 2rem;
            font-weight: 700;
        }

        .task {
            background-color: $color-white;
            border-radius: 5px;
            margin-bottom: 0.5rem;

            .taskContent {
                padding: 0.5rem;
            }

            button, a {
                font-size: 0.8rem;
                margin: 0;

                svg {
                    margin-left: 0;
                }
            }

            .entityName {
                font-size: 0.8rem;
                line-height: 0.8rem;
                font-weight: bold;
                color: $color-blue;
            }

            .priority {
                width: 3rem;
                height: 4px;
                background-color: $color-green;

                &.hight {
                    background-color: $color-error;
                }

                &.low {
                    background-color: $color-blue;
                }
            }

            .author {
                font-size: 0.7rem;
                line-height: 0.8rem;
                color: $color-gray-dark;
            }

            .assignee {
                font-size: 0.7rem;
                line-height: 0.8rem;
                color: $color-gray-text;
            }

            .deadline {
                font-size: 0.7rem;
                color: $color-error;
            }

            .mainButtons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0.3rem 0;
            }

            .additionalButtons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 0.2rem 0;

                button, a {
                    font-weight: normal;
                }
            }

            .moveButtons {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 0.2rem 0.5rem;
                background-color: $color-gray-light;
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }
    }
}




.buttons {
    margin: 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}