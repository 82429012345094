/*#region CKEditor components styles */
.cke_editable,
.cke_ltr {
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.cke_editable {
    margin: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cke_editable p,
.cke_editable div {
    width: 100%;
    max-width: 768px;
    box-sizing: border-box;
}


.cke_ltr > div.cke_panel_block {
    border: 1px solid #555;
    padding-top: 2rem;
    position: relative;
}

.cke_ltr > div.cke_panel_block > h1 {
    background-color: #555;
    color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
}
/*#endregion */


/*#region Content styles */

.cke-content p,
.cke_ltr p {
    margin: 0;
    padding: 0.2rem 0;
}

.cke-content h1,
.cke_ltr ul h1 {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    color: #222222;
}

.cke-content h2,
.cke_ltr h2 {
    font-size: 1.2rem;
    text-align: center;
    color: #222222;
}

.cke-content h3,
.cke_ltr h3 {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    color: #222222;
}

.cke-content .cke-attention,
.cke_ltr .cke-attention {
    background: #FEE6CD;
    color: #222;
    padding: 1rem;
}

.cke-content .cke-warning,
.cke_ltr .cke-warning {
    background: #F81348;
    color: #fff;
    padding: 1rem;
}

.cke-content .cke-success,
.cke_ltr .cke-success {
    background: #D8EBD0;
    color: #34782F;
    padding: 1rem;
}

.cke-content img {
    max-width: 100%;
}


/*#endregion */
